<template>
  <section>
     <pm-Breadcrumb :home="home" :model="items_bread" />

     <div class="row" >
      <div class="col-md-7">
        <h2 class="titulo-links-escolha">Configuracão do sistema</h2>
      </div>
    </div>

    <div class="pm-card">
      <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-8 lg:col-3">
            <label>Selecione o Fechamento presencial</label>
            <select class="p-inputtext p-component" v-model="fechamento"
              style="appearance: revert !important;width: 100% !important;" @change="updatedFechamento()" >
                <option value="Semanalmente"  >Semanalmente</option>
                <option value="Mensalmente"   >Mensalmente</option>
                <option value="Indeterminado" >Indeterminado</option>
            </select>
          </div>
          <div class="field col-12 md:col-12 lg:col-12">
            <p style="font-size: 17px;">Barra lateral</p>
            <label style="width: 3%;">
              Fundo
              <pm-ColorPicker v-model="barralateral_cor" />
            </label>
            <label style="margin: 10px;width: 3%;">
              Texto
              <pm-ColorPicker v-model="barralateral_cortext" />
            </label>
            <button class="btn btn-default" @click="updatedBarCor()">Alterar Cores</button>
          </div>
          <div class="field col-12 md:col-12 lg:col-12">
            <p style="margin-bottom: 38px;font-size: 20px;margin-top: 25px;">Quantidade de Presença por Ensino</p>

            <div class="row">
              <div class="col-md-12">
                <div class="row" style="border-bottom: 1px solid #ced4da; margin-bottom: 20px;padding-bottom: 15px;">
                  <div class="col-md-10">
                    <b style="padding-left: 26px;">Segmento</b>
                  </div>
                  <div class="col-md-2">
                    <b>Multiplas Presença diarias</b>
                  </div>
                </div>
              </div>
              <div class="col-md-12" v-for="n in ensinos.presenca_qdt" :key="n.segmento" >
                <div class="row" style="margin:20px">
                  <div class="col-md-10">
                    {{ n.segmento }}
                  </div>
                  <div class="col-md-2" style="text-align:center;">
                    <div>
                      <pm-InputSwitch v-model="n.presenca_infinita_bool"  @change="updatedPresenca(n.id,n.presenca_infinita_bool)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import axios from 'axios';
import { SegmentoEscolar } from "@/class/segmentoEscolar";

export default defineComponent({
  components:{

  },
  data() {
    return {
      home: { icon: "pi pi-home", to: "#" },
      items_bread: [{ label: "Configuração do sistema" }],
      pathDoSite: axios.defaults.baseURL.replace('api/v1',''),
      ensinos:[],
      fechamento:null,
      barralateral_cor:'ffffff',
      barralateral_cortext:'000000'
    };
  },
  beforeMount(){
    this.listarConfig()
  },
  methods:{
    async listarConfig() {
      let data = await axios.get('/config/sistema');
      this.fechamento = data.data.fechamento;
      this.barralateral_cor =  data.data.barralateral_cor.substring(1);
      this.barralateral_cortext = data.data.barralateral_cortext.substring(1);
      this.ensinos = data.data;
    },
    async updatedPresenca(id,check){
      let ck = 0;
      if(check){
        ck = 1;
      }
      const nv = { presenca_infinita: ck,}
      let data = await axios.put('/config/sistema/presenca/'+id,nv);
      this.listarConfig();
    },
    async updatedBarCor(){
      const nv = {barralateral_cor:'#'+this.barralateral_cor,barralateral_cortext:'#'+this.barralateral_cortext}
      let data = await axios.put('/config/sistema/sidebar-cor',nv);
      sessionStorage.setItem("colorBar", '#'+this.barralateral_cor);
      sessionStorage.setItem("colorBar_text", '#'+this.barralateral_cortext);
      location.reload()
    },
    async updatedFechamento(){
      const nv = {fechamento:this.fechamento}
      let data = await axios.put('/config/sistema/fecharmento',nv);
    }
  }
});
</script>

<style>
.va-button--normal{
  border-radius: 5px !important;
}

.btn-default {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}
.btn-default:hover {
  color: #fff;
  background-color: #8b8b8b;
  border-color: #8b8b8b;
}
</style>
